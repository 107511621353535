import React from 'react';
import './LandingCities.css';
import Delhi from '../../assets/landingcities/delhi.webp';
import Hyderabad from '../../assets/landingcities/hyderabad.webp';
import Banglore from '../../assets/landingcities/bangalore.webp';
import Mumbai from '../../assets/landingcities/mumbai.webp';
import Gurugram from '../../assets/landingcities/gurugram.webp';
import Ghaziabad from '../../assets/landingcities/ghaziabad.webp';
import Faridabad from '../../assets/landingcities/faridabad.webp';
import Noida from '../../assets/landingcities/noida.webp';
import GreaterNoida from '../../assets/landingcities/greaternoida.webp';
import Ballabhgarh from '../../assets/landingcities/ballabhgarh.webp';
import Narela from '../../assets/landingcities/narela.webp';

function LandingCities() {
  const cities = [
    { name: 'Delhi', image: Delhi, url: '/delhi' },
    { name: 'Mumbai', image: Mumbai, url: '/mumbai' },
    { name: 'Hyderabad', image: Hyderabad, url: '/hyderabad' },
    // { name: 'Bangalore', image: Banglore, url: '/bangalore' },
    { name: 'Gurugram', image: Gurugram , url: '/gurugram'},
    { name: 'Ghaziabad', image: Ghaziabad , url: '/ghaziabad'},
    { name: 'Faridabad', image: Faridabad , url: '/faridabad'},
    { name: 'Noida', image: Noida , url: '/noida'},
    { name: 'Greater Noida', image: GreaterNoida , url: '/greaternoida'},
    { name: 'Ballabhgarh', image: Ballabhgarh , url: '/ballabhgarh'},
    { name: 'Narela', image: Narela , url: '/narela'}
  ];

  return (
    <div>
      <h2 className="landingcitytitle">Professional Pet Services at Your City</h2>
      <div className="city-grid">
        {cities.map((city, index) => (
          <a key={index} href={city.url} className="city-item">
            <img src={city.image} alt={`${city.name} city` } className="city-image" />
            <span className="city-name">{city.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
}

export default LandingCities;
