import React, { useState, useEffect, useRef } from "react";
import Groomer1 from "../../assets/StarGroomers/nitin groomer.webp";
import Groomer2 from "../../assets/StarGroomers/sandeep groomer.webp";
import Groomer3 from "../../assets/StarGroomers/sunil groomer.webp";
import Groomer4 from "../../assets/StarGroomers/amitsaroj.webp";
import "./style.css";
import Rating from "./Rating";


const StarGroomer = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const groomers = [
    {
      id: 1,
      image: Groomer1,
      name: "Nitin",
      reviews: 324,
      rating: 5,
      numOfgrooming: 3684,
    },
    {
      id: 2,
      image: Groomer2,
      name: "Sandeep",
      reviews: 289,
      rating: 5,
      numOfgrooming: 3259,
    },
    {
      id: 3,
      image: Groomer3,
      name: "Sunil",
      reviews: 318,
      rating: 5,
      numOfgrooming: 3422,
    },
    {
      id: 4,
      image: Groomer4,
      name: "Amit",
      reviews: 180,
      rating: 5,
      numOfgrooming: 4820,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      setIsDesktop(width >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollRef = useRef(null);

  const onMouseDown = (e) => {
    const slider = scrollRef.current;
    slider.isDown = true;
    slider.startX = e.pageX - slider.offsetLeft;
    slider.scrollLeft = slider.scrollLeft;
  };

  const onMouseLeave = () => {
    const slider = scrollRef.current;
    slider.isDown = false;
  };

  const onMouseUp = () => {
    const slider = scrollRef.current;
    slider.isDown = false;
  };

  const onMouseMove = (e) => {
    const slider = scrollRef.current;
    if (!slider.isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - slider.startX) * 0.2; // scroll-fast
    slider.scrollLeft = slider.scrollLeft - walk;
  };

  return (
    <div className="starGroomer-Container mt-5">
      <h1 className="starGroomer-title">Star Groomers</h1>

      {isDesktop ? (
        <div
          className="horizontal-scroll"
          ref={scrollRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          {groomers.map((groomer) => (
            <div className="grommerCard" key={groomer.id}>
              <div className="groomerCardBody">
                <img
                  src={groomer.image}
                  className="groomerImg"
                  alt="profile"
                  loading="lazy"
                />
                <div className="groomerDetails">
                  <p className="GrommerNm m-0">{groomer.name}</p>
                  <Rating value={parseInt(groomer.rating)} totalStars={5} />
                  <p className="g-review m-0">Reviews ({groomer.reviews})</p>
                  <div className="gromerBottomLine">
                    <span className="GrommingNum">
                      {groomer.numOfgrooming}+
                    </span>
                    <span className="gmTxt">Pets groomed</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        // <Swiper
        //   spaceBetween={10}
        //   pagination={{
        //     clickable: true,
        //   }}

        //   modules={[Pagination]}
        //   className="mySwiper"
        // >
        //   {groomers.map((groomer) => (
        //     <SwiperSlide key={groomer.id}>
        //       <div className="grommerCard">
        //         <div className="groomerCardBody">
        //           <img
        //             src={groomer.image}
        //             className="groomerImg"
        //             alt="profile"
        //           />
        //           <div className="groomerDetails">
        //             <p className="GrommerNm m-0">{groomer.name}</p>
        //             <Rating value={parseInt(groomer.rating)} totalStars={5} />
        //             <p className="g-review m-0">Reviews ({groomer.reviews})</p>
        //             <div className="gromerBottomLine">
        //               <span className="GrommingNum">
        //                 {groomer.numOfgrooming}+
        //               </span>
        //               <span className="gmTxt">number of grooming</span>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </SwiperSlide>
        //   ))}
        // </Swiper>
      ) : (
        // Render column layout on mobile
        <div className="rowCard mt-3">
          {groomers.map((groomer) => (
            <div className="grommerCard" key={groomer.id}>
              <div className="groomerCardBody">
                <img
                  src={groomer.image}
                  className="groomerImg"
                  alt="profile"
                />
                <div className="groomerDetails">
                  <p className="GrommerNm m-0">{groomer.name}</p>
                  <Rating value={parseInt(groomer.rating)} totalStars={5} />
                  <p className="g-review m-0">Reviews ({groomer.reviews})</p>
                  <div className="gromerBottomLine">
                    <span className="GrommingNum">
                      {groomer.numOfgrooming}+
                    </span>
                    <span className="gmTxt">Pets groomed</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StarGroomer;
